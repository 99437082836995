const config = {
    brand: {
        name: ['De','Zonnebrillen','Webstore'],
        imgSrc: './frontpage.jpg'
    }, 
    account: {
        imgSrc: './accountpage.jpg'
    },
    cards: [
        {
            imgSrc: './news.jpg',
            title: 'Hello Sunshine!',
            subtitle: 'Geniet veilig van de zon met de zonnebrillen van George Harrison.'
        }
    ]
}

export { config }