import React from "react";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import { isValidUsername } from "./services/usernameValidator";
import { signInAsync } from "./services/signInService";
import { isValidPassword, getPasswordInvalidText } from "./services/passwordValidator";
import Paper from '@material-ui/core/Paper';
import PasswordTextField from "./PasswordTextField";
import './Component.css'

export default function LoginForm({
    setIsAuthenticated,
    setAuthenticatedUsername
}) {
    const history = useHistory()
    const [username, setUsername] = React.useState('')
    const [usernameError, setUsernameError] = React.useState(false)

    const [password, setPassword] = React.useState('')
    const [passwordError, setPasswordError] = React.useState(false)

    const [signInFailed, setSignInFailed] = React.useState(false)
    const [displayPassword, setDisplayPassword] = React.useState('')

    const [showPassword, setShowPassword] = React.useState(false)

    const _handleFormSubmit = (event) => {
        event.preventDefault()

        if (!isValidUsername(username)) {
            setUsernameError(true)
            return
        }

        if (!isValidPassword(password)) {
            setPasswordError(true)
            return
        }

        (async() => {
            if (! await signInAsync(username, password)) {
                setUsername('')
                setPassword('')
                setSignInFailed(true)
                return
            }

            setIsAuthenticated(true)
            setAuthenticatedUsername(username)
            history.push('/account')
            
        })()
    }

    const _handleUsernameChanged = (event) => {
        setUsernameError(false)
        setSignInFailed(false)
        const newValue = event.target.value
        setUsername(newValue)
    }

    const _handlePasswordChanged = (event) => {
        setPasswordError(false)
        setSignInFailed(false)
        const changedValue = event.target.value
        if (changedValue.length < password.length) {
            setPassword(password.slice(0, changedValue.length))
            setDisplayPassword(displayPassword.slice(0, changedValue.length))
        } else if (changedValue.length > 0) {
            const oldValue = showPassword ? password : displayPassword
            const newValue = password === '' ? changedValue : [password, changedValue.split(oldValue)[1]].join('')
            setPassword(newValue)
            const newDisplayPassword = new Array(newValue.length).fill('*').join('')
            setDisplayPassword(newDisplayPassword)
        } else {
            setPassword('')
            setDisplayPassword('')
        }
        
    }

    return (
        <React.Fragment>
            <div className="page-title">
                <Avatar style={{ marginRight: "20px" }} variant="rounded">
                    <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h4">Inloggen</Typography>
            </div>
            {!signInFailed ? null :
                <Paper style={{ padding: '10px', backgroundColor: '#ffecec' }}>
                    <Typography variant="caption">De combinatie van e-mailadres en wachtwoord is niet geldig.</Typography>
                </Paper>
            }
            <div className="page-content">
                <form noValidate style={{ display: "block" }} onSubmit={_handleFormSubmit}>
                    <TextField
                        error={usernameError}
                        helperText={usernameError ? "Het ingevulde gebruikersnaam is niet correct.": ""}
                        margin="normal"
                        fullWidth
                        required
                        id="username"
                        label="Gebruikersnaam"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        onChange={_handleUsernameChanged}
                        value={username}
                    />
                    <PasswordTextField
                        passwordError={passwordError}
                        onChangeCallback={_handlePasswordChanged}
                        password={password}
                        displayPassword={displayPassword}
                        passwordErrorMessage={getPasswordInvalidText()}
                        forceNoPasswordInputType={true}
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "18px", marginBottom: "18px" }}
                    >
                        Inloggen
                    </Button>
                    <div style={{ display: "grid", justifyContent: "flex-start" }}>
                        <Link to='/resetpassword' style={{marginBottom: '18px'}}>
                            <u>{"Wachtwoord vergeten?"}</u>
                        </Link>
                        <Link to='/signup'>
                            <u>{"Maak een account aan"}</u>
                        </Link>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}
