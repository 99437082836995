const isValidPassword_shop1 = (password) => {
    return password.length > 0
}

const getPasswordInvalidText_shop1 = () => {
    return 'Het wachtwoord mag niet leeg zijn.'
}

const isValidPassword_shop4 = (password) => {
    return password.length >= 6 && password.length <=15
}

const getPasswordInvalidText_shop4 = () => {
    return 'Het wachtwoord moet minimaal 6 en maximaal 15 tekens bevatten.'
}


const illegalChars = `!#$%'()*+,-./:;=`
const isValidPassword_shop5 = (password) => {
    const hasIllegalChars = (illegalChars.split('')).some( x => password.includes(x) )
    return password.length > 0 && !hasIllegalChars
}

const getPasswordInvalidText_shop5 = () => {
    return `Het wachtwoord mag niet leeg zijn en mag geen van deze tekens bevatten: ${illegalChars}`
}

export { 
    isValidPassword_shop1 as isValidPassword,
    getPasswordInvalidText_shop1 as getPasswordInvalidText,
    isValidPassword_shop4 as isStrictValidPassword,
    getPasswordInvalidText_shop4 as getStrictPasswordInvalidText
}